import get from 'lodash/get';
import languages from '../constants/languages.json';

export const dicionaries = languages.list.reduce((acc, { value }) => ({
  ...acc,
  [value]: () => import(`../locales/${value}.json`)
}), {});

export class I18n {
  constructor(dictionary, lang) {
    this.dictionary = dictionary;
    this.lang = lang;
  }

  t(key) {
    const raw = get(this.dictionary, key);

    if (!raw) {
      console.warn('No translation found for key: ' + key);
    }

    return raw;
  }

  async changeLanguage(langCode) {
    if (!dicionaries[langCode]) {
      return false;
    }
    
    this.dictionary = await dicionaries[langCode]();
    this.lang = langCode;
    return true;
  }
}