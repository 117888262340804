import IntroImg from '../assets/schedule-example.webp';
import IntroImg550 from '../assets/schedule-example_550.webp';
import IntroImg500 from '../assets/schedule-example_500.webp';
import IntroImg450 from '../assets/schedule-example_450.webp';
import IntroImg400 from '../assets/schedule-example_400.webp';
import { createRouter, createWebHistory } from 'vue-router';
import { dicionaries } from '@/classes/I18n';

const introImageVariations = [
  {
    name: 'intro-image',
    src: IntroImg,
    media: '(min-width: 575.1px)'
  },
  {
    name: 'intro-image_550',
    src: IntroImg550,
    media: '(min-width: 550.1px) and (max-width: 575px)'
  },
  {
    name: 'intro-image_500',
    src: IntroImg500,
    media: '(min-width: 500.1px) and (max-width: 550px)'
  },
  {
    name: 'intro-image_450',
    src: IntroImg450,
    media: '(min-width: 450.1px) and (max-width: 500px)'
  },
  {
    name: 'intro-image_400',
    src: IntroImg400,
    media: '(max-width: 450px)'
  }
];

const preLoadImages = (variations) => {
  variations.forEach(({ src, name, media }) => {
    if (!document.querySelector(`[data-name="${name}"]`)) {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = src;
      link.media = media;
      link.type = 'image/webp';
      link.setAttribute('fetchpriority', 'high');
      link.setAttribute('data-name', name);
      document.head.appendChild(link);
    }
  })
};

export const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  strict: true,
  routes: [
    {
      path: '/',
      component: () => import('../components/routes/App.vue'),
      beforeEnter: () => preLoadImages(introImageVariations),
      name: 'App'
    },
    {
      path: '/:locale',
      component: () => import('../components/routes/App.vue'),
      beforeEnter: () => preLoadImages(introImageVariations),
      name: 'AppLocale'
    },
    {
      path: '/en',
      redirect: { path: '/' }
    },
    {
      path: '/s/:schedule',
      component: () => import('../components/routes/App.vue'),
      name: 'AppShared'
    },
    {
      path: '/:locale/s/:schedule',
      component: () => import('../components/routes/App.vue'),
      name: 'AppSharedLocale'
    },
    {
      path: '/en/s/:schedule',
      redirect: (to) => ({ path: `/s/${to.params.schedule}` })
    },
    {
      path: '/suggestions',
      component: () => import('../components/routes/Suggestions.vue'),
      meta: {
        title: 'Improvement suggestions | Schedule Builder online',
        description: 'Browse, upvote and request new features or fixes that you want added to the tool'
      }
    },
    {
      path: '/blog',
      component: () => import('../components/routes/Blog.vue'),
      meta: {
        title: 'Productivity center (Blog) | Schedule Builder online',
        description: 'Tips, advice and news to help boost your productivity'
      }
    },
    {
      path: '/blog/:articleId',
      component: () => import('../components/routes/Blog.vue')
    },
    {
      path: '/contact',
      component: () => import('../components/routes/Contact.vue'),
      meta: {
        title: 'Contact us | Schedule Builder online',
        description: 'Contact us regarding support or marketing related topics'
      }
    },
    {
      path: '/privacy-policy',
      component: () => import('../components/routes/Privacy.vue'),
      meta: {
        title: 'Privacy Policy | Schedule Builder online',
        description: 'This Privacy Policy describes how ScheduleBuilder.org collects, uses, and shares information when you use our website'
      }
    },
    {
      path: '/terms-of-conditions',
      component: () => import('../components/routes/Terms.vue'),
      meta: {
        title: 'Terms of Conditions  | Schedule Builder online',
        description: 'These Terms and Conditions ("Terms") govern your use of the Schedule Builder service'
      }
    },
    {
      path: '/embed/:schedule',
      component: () => import('../components/sections/Schedule.vue'),
      name: 'Embed'
    },
    {
      path: '/:locale/embed/:schedule',
      component: () => import('../components/sections/Schedule.vue'),
      name: 'EmbedLocale'
    },
    {
      path: '/old',
      component: () => import('../components/routes/Old.vue'),
      meta: {
        title: 'Old app | Schedule Builder online',
        description: 'Access and use the old version of Schedule Builder Online'
      }
    },
    {
      path: '/404',
      name: 'NotFound',
      component: () => import('../components/routes/404.vue'),
      meta: {
        title: '404 not found | Schedule Builder online',
        description: 'Page not found'
      }
    },
    {
      path: "/:pathMatch(.*)*",
      beforeEnter: () => window.location = `/404`
    }
  ]
});

router.beforeEach((to) => {
  const { locale } = to.params;
  const { meta } = to;

  // Check if translation exists
  if (locale && !dicionaries[locale]) {
    return { name: 'NotFound' };
  }

  if (meta && Object.keys(meta).length) {
    const { title, description } = meta;

    document.title = title;
    document.querySelector('[name="description"]').setAttribute('content', description)
  }

  return true;
});

router.afterEach((to) => {
  to.meta.loaded = true;
});