<template>
  <template v-if="isEmbedRoute">
    <router-view></router-view>
  </template>
  <template v-else>
    <HeaderComponent />
    <router-view></router-view>
    <FooterComponent v-if="routeLoaded && (isIntroMode || !(isAppRoute && isMobileDevice))" />
    <ModalComponent
      v-if="currentModal"
      :title="currentModal.title"
      :icon="currentModal.icon"
      :hasTabs="currentModal.hasTabs"
    >
      <component
        :is="currentModal.type"
        :data="modalProps"
      ></component>
    </ModalComponent>
  </template>
</template>

<style lang="scss">
html {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
    min-height: 7px;
    z-index: 5;

    &-thumb {
      border-radius: 4px;
      min-width: 50px;
      height: 7px;
      min-height: 7px;
      background-color: #7ac0e3;
      -webkit-box-shadow: 0 0 1px hsla(0, 0%, 100%, .5);
      z-index: 5;
    }
  }
}

input[type="color"] {
  cursor: pointer;

  &::-moz-color-swatch {
    border: none;
  }

  &::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 0;
  }

  &::-webkit-color-swatch {
    border: none;
  }
}

body {
  padding: 0;
  margin: 0;
  background: linear-gradient(90deg, rgba(78, 175, 222, 0.75) 0%, rgba(80, 92, 131, 0.75) 50%, rgba(192, 40, 78, 0.75) 100%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;

  #app {
    color: var(--main-color);

    &[data-mode-theme="light"] {
      --main-bg-color: rgb(255, 255, 255);
      --main-bg-color-transparent: rgba(255, 255, 255, 0.75);
      --standout-bg-color: #ededff;
      --standout-alt-bg-color: #ffedf9;
      --box-shadow-color: rgba(0, 0, 0, 0.2);
      --main-color: #2f2f6a;
      --main-color-transparent: rgb(47, 47, 106, 0.75);
      --border-color: rgba(73, 80, 87, 0.3);
      --border-color-solid: rgb(170, 179, 189);
      --border-color-transparent: rgba(7, 80, 87, 0.1);
      --border-color-alt: #adadfd;
      --selection-color: #e7f8ff;
      --selection-color-transparent: rgba(231, 248, 255, 0.75);
      --selection-color-alt: #b3d6e5;
      --icon-color: #5d8fc1;
      --cell-selection-color: rgba(255, 255, 255, 0.75);
      --cell-sticky-color: #deeff7;
      --bg-color-disabled: rgb(0 0 0 / 5%);
      --border-color-disabled: rgb(0 0 0 / 10%);
      --link-color: #247cd5;
    }

    &[data-mode-theme="dark"] {
      backdrop-filter: brightness(0.25);
      --main-bg-color: rgb(5, 32, 44);
      --main-bg-color-transparent: rgba(5, 32, 44, 0.75);
      --standout-bg-color: #00151e;
      --standout-alt-bg-color: #00151e;
      --box-shadow-color: rgba(255, 255, 255, 0.2);
      --main-color: white;
      --main-color-transparent: rgba(255, 255, 255, 0.75);
      --border-color: rgba(150, 157, 165, 0.3);
      --border-color-solid: rgb(150, 157, 165);
      --border-color-transparent: rgba(150, 157, 165, 0.1);
      --border-color-alt: white;
      --selection-color: #0f455a;
      --selection-color: rgba(15, 69, 90, 0.75);
      --selection-color-alt: #0f455a;
      --icon-color: #78bcff;
      --cell-selection-color: #082938;
      --cell-sticky-color: #0c242f;
      --link-color: #247cd5;

      input[type="time"]::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    }

    input,
    select,
    textarea {
      color: var(--main-color);

      option {
        background: var(--main-bg-color);
        color: var(--main-color);
      }
    }

    hr {
      width: 100%;
      border: 0;
      border-bottom: 1px solid var(--border-color);
    }

    // Toggle overflown ads
    [data-ad][data-overflown="true"]>div {
      display: none;
    }

    button {
      &[data-type="danger"] {
        background: #ffe7e7;
      }

      &[data-type="alt"] {
        background: #e7f8ff;

        &:hover {
          background: var(--main-bg-color) !important;
        }
      }
    }

    .icon-flip svg {
      -moz-transform: scale(-1, 1);
      -webkit-transform: scale(-1, 1);
      -o-transform: scale(-1, 1);
      -ms-transform: scale(-1, 1);
      transform: scale(-1, 1);
    }
  }
}

@media only screen and (max-width: 1300px) {
  header .nav-items {
    .nav-toggles {
      gap: 1rem !important;

      .select[data-mode="screen"] {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .features {
    .group {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 800px) {
  #app>.container {
    .toolbar {
      justify-content: center;
      gap: 2rem;

      &__button {
        flex-grow: 0;
        width: auto;
        border-left: none !important;

        span {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  #app>.container {
    .toolbar {
      gap: 1rem;
    }
  }

  .footer {
    &__container {
      flex-direction: column;
      gap: 1rem !important;
      padding-bottom: 1rem !important;

      &__section {
        text-align: center;

        &:not(:last-of-type) {
          padding-bottom: 1rem;
          border-bottom: 1px solid var(--border-color);
        }
      }
    }
  }
}

@media only screen and (max-width: 968px) {
  #app {
    header {
      background: var(--main-bg-color);
      border-bottom: 1px solid var(--border-color);
    }

    >.container {
      margin: 0;
      gap: 0;
      height: calc(100vh - 67px);

      .schedule {
        border-radius: 0;
        width: auto;
        height: calc(100vh - 67px - 56px);
        box-shadow: none;
        width: 100% !important;
      }

      @supports(height: 100dvh) {
        height: calc(100dvh - 67px);

        .schedule {
          height: calc(100dvh - 67px - 56px);
        }
      }
    }

    .toolbar {
      background: var(--main-bg-color);
      flex-grow: 1;
      border-top: 1px solid var(--border-color);
      width: 100% !important;
      gap: 0;

      &__button {
        background: none;
        box-shadow: none;
        border-radius: 0;

        &:first-of-type {
          color: #566cd9;
        }

        &:not(:first-of-type) {
          border-left: 1px solid var(--border-color);
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  #app>.container {
    .toolbar {
      gap: 0.5rem !important;

      &__button {
        padding: 0.5rem;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .nav-links {
    display: none !important;
  }
}

@media only screen and (max-width: 450px) {
  #app>.container {
    .toolbar {
      gap: 0.5rem !important;

      &__button {
        padding: 0.5rem;
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .nav-toggles .select:not([data-mode="locale"]) {
    span {
      display: none;
    }
  }

  .select[data-mode="theme"] {
    display: none !important;
  }
}

@media only screen and (max-width: 300px) {
  .nav-toggles .select {
    span {
      display: none;
    }
  }

  .toolbar {
    &__button[data-button-id="3"] {
      display: none !important;
    }
  }
}
</style>

<script>
import { defineAsyncComponent, nextTick } from 'vue'
import { mapState, mapActions } from 'pinia';
import { useSystemStore } from '@/stores/system.store';
import { setSystemModes } from './helpers/localStorage.helper';

export default {
  name: 'MainComponent',
  components: {
    HeaderComponent: defineAsyncComponent(() => import('./components/sections/Header.vue')),
    ModalComponent: defineAsyncComponent(() => import('./components/elements/Modal.vue')),
    EventModal: defineAsyncComponent(() => import('./components/modals/EventModal.vue')),
    SettingsModal: defineAsyncComponent(() => import('./components/modals/SettingsModal.vue')),
    BackgroundModal: defineAsyncComponent(() => import('./components/modals/BackgroundModal.vue')),
    SaveModal: defineAsyncComponent(() => import('./components/modals/SaveModal.vue')),
    LoginModal: defineAsyncComponent(() => import('./components/modals/LoginModal.vue')),
    /*PlansModal: defineAsyncComponent(() => import('./components/modals/PlansModal.vue')),*/
    ProfileModal: defineAsyncComponent(() => import('./components/modals/ProfileModal.vue')),
    FooterComponent: defineAsyncComponent(() => import('./components/sections/Footer.vue'))
  },
  data: function () {
    return {
      routeLoaded: false
    };
  },
  computed: {
    ...mapState(useSystemStore, ['modes', 'window', 'modal', 'introDismissed']),
    modalProps() {
      // eslint-disable-next-line no-unused-vars
      const { type, title, icon, hasTabs, ...rest } = this.currentModal;
      return rest;
    },
    urlLocale() {
      return this.$route.params.locale;
    },
    isAppRoute() {
      return ['App', 'AppLocale', 'AppShared', 'AppSharedLocale'].includes(this.$route.name);
    },
    isEmbedRoute() {
      return ['Embed', 'EmbedLocale'].includes(this.$route.name);
    },
    url() {
      return `${window.location.origin}${this.$route.path !== '/' ? this.$route.path : ''}`;
    },
    i18nLocale() {
      return this.i18n.value.lang;
    },
    currentModal() {
      const { modal } = this;
      return modal.length ? modal.slice(-1)[0] : null;
    },
    isIntroMode() {
      return !(this.$route.params.schedule || this.introDismissed);
    },
    isMobileDevice() {
      return this.window.innerWidth <= 968;
    },
    potentialLocaleChange() {
      return this.i18nLocale + this.$route.matched?.[0]?.components.default.name;
    }
  },
  methods: {
    ...mapActions(useSystemStore, ['initApp']),
    setDocumentLang(locale) {
      const isLocalised = location.pathname.split('/').includes(locale);
      const html = document.querySelector('html');
      html.setAttribute('lang', isLocalised ? locale : 'en');
    },
    routeLocalise() {
      const [matched] = this.$route.matched;

      if (matched) {
        const { path: currentPath } = this.$route;
        const { path } = matched;
        const { routes } = this.$router.options;

        const isDefaultLocale = this.modes.locale === 'en';
        const pathLocalisationExists = this.urlLocale || routes.some((r) => r.path === `/:locale${path}`.replace(/\/$/, ''));

        if (isDefaultLocale && this.urlLocale) {
          this.$router.push(`/${currentPath.split('/').slice(2).join('/')}`);
        } else if (!isDefaultLocale && pathLocalisationExists && this.urlLocale !== this.modes.locale) {
          this.$router.push(this.urlLocale ? currentPath.replace(`/${this.urlLocale}`, `/${this.modes.locale}`) : `/${this.modes.locale}${currentPath}`.replace(/\/$/, ''));
        }
      }
    },
    injectCanoncial(url) {
      const current = document.querySelector('link[rel="canonical"]');
      const link = current ?? document.createElement('link');
      const isAppRoute = this.$route.matched?.[0]?.components.default.name === 'AppRoute';
      const isSavedSchedule = url.includes('/s/');

      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', isAppRoute && isSavedSchedule ? url.split('/').slice(0, -2).join('/') : url);

      if (!current) {
        document.head.appendChild(link);
      }
    },
    applyLocalisation() {
      this.routeLocalise();
      this.setDocumentLang(this.modes.locale);
      this.injectCanoncial(this.url);

      if (!this.$route.matched?.length || this.$route.matched?.[0]?.components.default.name === 'AppRoute') {
        document.title = this.i18n.value.t('meta.title');
        document.querySelector('[name="description"]').setAttribute('content', this.i18n.value.t('meta.description'))
      }
    },
    async checkLocale() {
      const { locale } = this.modes;

      if (this.i18n.value.lang !== locale) {
        await this.i18n.value.changeLanguage(locale);
        this.applyLocalisation();
      }
    },
    applyViewportWidthStyle() {
      // Apply viewport width (without px for calc)
      document.documentElement.style.setProperty('--vw', `${document.body.offsetWidth}`);
    },
    applyTheme() {
      document.querySelector('#app').setAttribute('data-mode-theme', this.modes.theme);
    },
    addResizeListener() {
      const vm = this;

      addEventListener("resize", () => {
        const { innerHeight, innerWidth } = window;
        const { initialWidth } = vm.window;

        vm.window.innerWidth = innerWidth;
        vm.window.innerHeight = innerHeight;

        this.applyViewportWidthStyle();

        if (Math.abs(innerWidth - initialWidth) > 25) {
          if (vm.modes.zoom === 'off' || innerWidth >= 732) {
            vm.modes.scope = innerWidth < 732 ? 'daily' : 'weekly';
          }
        }
      }, { passive: true });
    }
  },
  mounted() {
    this.initApp(this.i18n?.value.lang);
    this.applyTheme();
    this.applyViewportWidthStyle();
    this.addResizeListener();
  },
  watch: {
    modes: {
      handler: function (modes) {
        // Update db
        setSystemModes(modes);
      },
      deep: true
    },
    'modes.locale'() {
      this.checkLocale();
    },
    potentialLocaleChange() {
      this.applyLocalisation();
    },
    'modes.theme'(theme) {
      document.querySelector('#app').setAttribute('data-mode-theme', theme);
    },
    'modes.scope'() {
      this.window.initialWidth = innerWidth;
    },
    async urlLocale(locale) {
      if (locale && this.modes.locale !== locale) {
        this.modes.locale = locale;
      }
    },
    url(url) {
      this.injectCanoncial(url);
      this.setDocumentLang(this.modes.locale);
    },
    '$route.meta.loaded'() {
      const vm = this;
      nextTick(() => setTimeout(() => {
        vm.routeLoaded = true
      }, 100));
    }
  }
}
</script>
