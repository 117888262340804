import { markRaw } from 'vue';

const firebaseConfig = {
  apiKey: 'AIzaSyBpOErj1J6mk0n8ipJbGOGJ5tDs4mcdeA4',
  authDomain: 'schedulebuilder-41fda.firebaseapp.com',
  databaseURL: 'https://schedulebuilder-41fda.firebaseio.com',
  projectId: 'schedulebuilder-41fda',
  storageBucket: 'schedulebuilder-41fda.appspot.com',
  messagingSenderId: '695243686376',
  appId: '1:695243686376:web:15f7db5bed36222e56245d',
  measurementId: 'G-Q9QZKSE064',
  cacheSizeBytes: -1,
};

const firebase = async () => {
  const { initializeApp } = await import('firebase/app');
  const { CACHE_SIZE_UNLIMITED } = await import('firebase/firestore');

  return await initializeApp({
    ...firebaseConfig,
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
  });
};
const firestore = async (app) => {
  const { initializeFirestore, persistentLocalCache } = await import('firebase/firestore');
  const db = await markRaw(initializeFirestore(app, { localCache: persistentLocalCache() }));

  return db;
};
const analytics = async (app) => {
  const { getAnalytics } = await import('firebase/analytics');
  getAnalytics(app);
};

export const initApp = async () => {
  const app = await firebase();
  analytics(app);
  return app;
};

export const getDb = async (app) => {
  return await firestore(app);
};

export const getSchedule = async (db, id) => {
  const { getDoc, doc, getDocFromCache } = await import('firebase/firestore');
  const ref = doc(db, 'calendars', id);

  let schedule = null;
  try {
    schedule = await getDocFromCache(ref);
  } catch (e) {
    schedule = await getDoc(ref);
  }

  return schedule;
};

export const getUserSchedule = async (db, uid) => {
  const { getDoc, doc } = await import('firebase/firestore');
  const ref = doc(db, `/users/${uid}/schedules`, uid);

  let schedule = null;
  try {
    schedule = await getDoc(ref);
  } catch (e) {
    console.log(e)
    console.log('No schedule exists. Creating a new one.')
  }

  return schedule;
};

export const getUser = async (db, uid, fromCache = true) => {
  const { getDoc, doc, getDocFromCache } = await import('firebase/firestore');
  const ref = doc(db, 'users', uid);

  

  let user = null;
  try {
    user = fromCache ? await getDocFromCache(ref) : await getDoc(ref);
  } catch {
    try {
      user = await getDoc(ref);
    } catch (e) {
      console.log(e);
    }
  }

  return user?.data();
};

export const setSchedule = async (db, id, schedule) => {
  try {
    const { setDoc, doc } = await import('firebase/firestore');
    const ref = doc(db, `/users/${id}/schedules`, id);
    await setDoc(ref, schedule);
    return true;
  } catch (e) {
    console.log(e)
    return false;
  }
};

export const removeUserSchedule = async (db, id) => {
  const { doc, deleteDoc } = await import("firebase/firestore");

  await deleteDoc(doc(db, `/users/${id}/schedules`, id));

  return true;
};

export const createShare = async (db, schedule, uid) => {
  const { collection, addDoc } = await import("firebase/firestore");
  const { getIp } = await import('../api/Firebase.api');

  const doc = await addDoc(collection(db, 'calendars'), {
    ip: uid ? null : ((await getIp())?.result ?? ''),
    ...schedule,
    uid: uid ?? null
  });

  return doc.id;
};

export const removeShare = async (db, scheduleId) => {
  const { doc, deleteDoc } = await import("firebase/firestore");

  await deleteDoc(doc(db, 'calendars', scheduleId));

  return true;
};