export const diff = (schedule, oldSchedule) => {
  const { events, settings } = schedule;
  const { events: oldEvents, settings: oldSettings } = oldSchedule;

  return {
    events: {
      added: oldEvents.filter((oe) => !events.some((e) => e.id === oe.id)),
      removed: events.filter((e) => !oldEvents.some((oe) => e.id === oe.id)),
      updated: oldEvents
        .filter((oe) => !events.some((e) => JSON.stringify(e) === JSON.stringify(oe)))
        .map((oe) => ({ was: oe, became: events.find((e) => e.id === oe.id) }))
    },
    settings: {
      became: Object.fromEntries(
        Object.entries(settings).filter((setting) => (
          !Object.entries(oldSettings).some((oldSetting) => JSON.stringify(setting) === JSON.stringify(oldSetting))
        ))
      ),
      was: Object.fromEntries(
        Object.entries(oldSettings).filter((oldSetting) => (
          !Object.entries(settings).some((setting) => JSON.stringify(setting) === JSON.stringify(oldSetting))
        ))
      )
    }
  }
};