import { addIcons } from "oh-vue-icons";
import {
  FaRegularCalendarPlus, MdEditcalendar, BiImage, BiTag, BiCalendarDate, BiInfoCircle, PxCalendarWeek,
  FaRegularClock, FaCalendarWeek, MdFormatlinespacingRound, FaRulerVertical, CoPaintBucket, BiSearch,
  FaSpinner, FaDownload, FaLink, FaRegularFileImage, RiPrinterLine, BiFileEarmarkText, FaHandSparkles,
  BiStars, FaPencilRuler, FaUserAlt, FaFacebookSquare, FaTwitterSquare, FaYoutubeSquare, CoVueJs,
  CoFirebase, FaEnvelope, FaHourglassHalf, CoLanguage, MdFullscreenRound, MdFullscreenexitRound,
  BiTable, FaCalendarDay, MdLightmode, MdDarkmode, FaRegularCaretSquareLeft, FaShareSquare,
  FaRegularCaretSquareRight, GiBroom, FaUpload, FaRegularThumbsUp, BiCodeSlash, FaListOl,
  HiSolidEyeOff, HiSolidEye, BiLayoutTextWindowReverse, BiCheckCircle, IoAlertCircleOutline,
  BiPlusCircle, MdEventRound, PrArrowsV, BiXCircle, FaIcons, FaFont, FaCogs, FaCalendarAlt,
  FaSave, BiTagFill, FaCubes, MdImagesearch, MdPlaylistaddcheckRound, FaAlignCenter,
  MdSettingssuggestOutlined, FaRocket, BiYoutube, MdCropportraitTwotone,  MdCroplandscapeTwotone,
  MdPaletteRound, BiZoomIn, BiZoomOut, IoLayers, FaUndo, FaRedo, MdPasswordRound,
  BiGoogle, CoWindows, CoFacebookF, RiUserFollowFill, HiSolidChevronDoubleUp   , OiLinkExternal,
  BiTrash3, BiHourglassSplit  
} from "oh-vue-icons/icons";

export const getIconName = (icon) => icon.split("")
  .map((c, i) => `${c.match(/[A-Z]/) && i > 0 ? "-" : ""}${c}`)
  .join("")
  .toLowerCase();

export const importIcons = () => addIcons(
  FaRegularCalendarPlus, MdEditcalendar, BiImage, BiTag, BiCalendarDate, BiInfoCircle, PxCalendarWeek,
  FaRegularClock, FaCalendarWeek, MdFormatlinespacingRound, FaRulerVertical, CoPaintBucket, BiSearch,
  FaSpinner, FaDownload, FaLink, FaRegularFileImage, RiPrinterLine, BiFileEarmarkText, FaHandSparkles,
  BiStars, FaPencilRuler, FaUserAlt, FaFacebookSquare, FaTwitterSquare, FaYoutubeSquare, CoVueJs,
  CoFirebase, FaEnvelope, FaHourglassHalf, CoLanguage, MdFullscreenRound, MdFullscreenexitRound,
  BiTable, FaCalendarDay, MdLightmode, MdDarkmode, FaRegularCaretSquareLeft, FaShareSquare,
  FaRegularCaretSquareRight, GiBroom, FaUpload, FaRegularThumbsUp, BiCodeSlash, FaListOl,
  HiSolidEyeOff, HiSolidEye, BiLayoutTextWindowReverse, BiCheckCircle, IoAlertCircleOutline,
  BiPlusCircle, MdEventRound, PrArrowsV, BiXCircle, FaIcons, FaFont, FaCogs, FaCalendarAlt,
  FaSave, BiTagFill, FaCubes, MdImagesearch, MdPlaylistaddcheckRound, FaAlignCenter,
  MdSettingssuggestOutlined, FaRocket, BiYoutube, MdCropportraitTwotone,  MdCroplandscapeTwotone,
  MdPaletteRound, BiZoomIn, BiZoomOut, IoLayers, FaUndo, FaRedo, MdPasswordRound,
  BiGoogle, CoWindows, CoFacebookF, RiUserFollowFill, HiSolidChevronDoubleUp   , OiLinkExternal,
  BiTrash3, BiHourglassSplit  
);

export const fetchIcon = async (name) => {
  const icon = await (await fetch(`https://assets.schedulebuilder.org/icons/${name}.json`)).json();
  addIcons(icon);
  return icon.name;
};

export const fetchAllIcons = async () => {
  const icons = await (await fetch(`/allIcons.json`)).json();
  addIcons(...Object.values(icons));
};