import { nextTick } from 'vue';
import { defineStore } from 'pinia';
import { getSchedule, saveLocalSchedule, removeHistory, getHistory, resetSchedule } from '@/helpers/localStorage.helper';
import { getUserSchedule, getSchedule as getSharedSchedule, setSchedule, createShare, removeShare, removeUserSchedule } from '@/helpers/firebase.helper';

const getBrowserClockType = () => {
  try {
    return new Intl.DateTimeFormat(navigator.language, { hour: "numeric" }).format(0).match(/AM/)
      ? '12-hour'
      : '24-hour';
  } catch {
    return '24-hour';
  }
};

export const defaultSettings = {
  showTitle: false,
  clockType: getBrowserClockType(),
  lineSpacing: 60,
  timeRange: [8, 17],
  showDuration: true,
  background: {
    type: 0,
    value: null
  },
  firstDayOfWeek: 0,
  autoEventColor: false,
  stretchEvents: true,
  weekDays: [0, 1, 2, 3, 4, ...(window.innerWidth >= 400 ? [5, 6] : [])],
  fontFamily: 'Roboto Slab',
  fontColor: {
    light: '#2f2f6a',
    dark: '#ffffff'
  },
  centerText: true,
  showDates: false,
  weekNumber: null  
};

const defaultHistory = {
  items: [],
  index: -1,
  ready: false
};

export const useScheduleStore = defineStore('schedule', {
  state: () => {
    return {
      id: null,
      title: '',
      settings: {
        ...defaultSettings
      },
      events: [],
      history: {
        ...defaultHistory
      },
      lastSaveId: '',
      lastChangeTime: null,
      lastShareTime: null
    }
  },
  actions: {
    initSchedule({ title, settings, events, lastSaveId, id, lastChangeTime }) {
      this.title = title ?? '';
      this.id = id;

      if (!settings.hasWeekend && !settings.weekDays) {
        settings.weekDays = [0, 1, 2, 3, 4];
      }

      if (typeof settings.fontColor === 'string') {
        settings.fontColor = {
          light: settings.fontColor,
          dark: '#ffffff'
        }
      }

      if (lastChangeTime) {
        this.lastChangeTime = lastChangeTime;
      }

      this.settings = {
        ...this.settings,
        ...settings
      };

      // Adjust background
      const { value, thumbnail } = this.settings.background;
      if (thumbnail && value.includes('pixabay.com/get/')) {
        this.settings.background.value = thumbnail.replace('_150', '_1280');
      }

      this.events = events;
      this.lastSaveId = lastSaveId;
    },
    setEvent(event) {
      const { day, days, ...rest } = event;

      if (rest.id) {
        const index = this.events.findIndex((e) => e.id === rest.id);
        const updatedEvent = {
          ...rest,
          day: day ?? days[0]
        };
        this.events[index] = updatedEvent;
      } else {
        days.forEach((day) => this.events.push({
          ...rest,
          id: Math.max(0, ...this.events.map((e) => e.id ?? 1)) + 1,
          day,
        }));
      }

      // Check if event overflows schedule
      const startHour = parseInt(event.timeRange[0].split(':')[0]);
      const endHour = parseInt(event.timeRange[1].split(':')[0]) + (event.timeRange[1].endsWith('00') ? 0 : 1);
      if (startHour < this.settings.timeRange[0]) {
        this.settings.timeRange[0] = startHour;
      }
      if (endHour > this.settings.timeRange[1]) {
        this.settings.timeRange[1] = endHour;
      }

      this.saveLocalSchedule();
    },
    removeEvent(event) {
      const index = this.events.findIndex((e) => e.id === event.id);
      this.events.splice(index, 1);

      this.saveLocalSchedule();
    },
    updateTitle(title) {
      this.title = title;
      this.saveLocalSchedule();
    },
    updateSettings(settings) {
      this.settings = { ...this.settings, ...settings };

      this.saveLocalSchedule();
    },
    saveLocalSchedule() {
      this.history.index++;
      const history = JSON.parse(JSON.stringify(this.history));

      nextTick(() => {
        const { items, lastChangeTime } = saveLocalSchedule({
          title: this.title,
          settings: this.settings,
          events: this.events,
          lastSaveId: this.lastSaveId
        }, this.id, history);

        this.history.lastChangeTime = lastChangeTime;
        this.setLastChangeTime(history.lastChangeTime);

        if (!history.ready) {
          this.history.items = items;
        }
      });
    },
    async saveServerSchedule(db) {
      const { history, ...rest } = this.$state;

      if (history.lastChangeTime && !this.lastChangeTime || this.lastChangeTime < history.lastChangeTime) {    
        const result = await setSchedule(db, this.id, {
          ...rest,
          lastChangeTime: history.lastChangeTime
        });

        if (result) {
          this.setLastChangeTime(history.lastChangeTime);
        }
      }
    },
    setLastSaveId(id) {
      this.lastSaveId = id;
      this.saveLocalSchedule();
    },
    async removeSchedule(id, isUserSchedule, db) {
      if (isUserSchedule) {
        await removeUserSchedule(db, id);
      }
      resetSchedule(id);
    },
    resetSchedule(id) {      
      this.history = {
        ...defaultHistory
      };
      this.events = [];
      this.settings = defaultSettings;      
      this.id = id;
    },
    undo() {
      this.history.index -= 2;
      this.history.ready = true;
      this.saveLocalSchedule();
    },
    redo() {
      this.history.ready = true;
      this.saveLocalSchedule();
    },
    resetHistory() {
      removeHistory();
      this.history = {
        ...defaultHistory
      };
    },
    setLastChangeTime(timeStamp) {
      this.lastChangeTime = timeStamp;
    },
    async loadServerSchedule(db, id, isUserSchedule) {
      const schedule = await (
        isUserSchedule
          ? getUserSchedule(db, id)
          : getSharedSchedule(db, id)
      );

      if (schedule.exists()) {
        this.initSchedule({
          ...schedule.data(),
          id
        });
      } else {
        this.resetSchedule(id);
      }
    },
    loadLocalSchedule(id, isUserSchedule) {
      const schedule = JSON.parse(getSchedule(id));

      if (schedule) {
        const isHistorySyncedWithServer = this.history.lastChangeTime <= schedule.lastChangeTime;

        if (!isUserSchedule || isHistorySyncedWithServer) {
          this.initSchedule({
            ...schedule,
            id
          });
        } else {
          this.resetHistory();
        }
      }
    },
    loadHistory(scheduleId) {
      const history = getHistory(scheduleId);

      if (history) {
        Object.entries(history).forEach(([k, v]) => this.history[k] = v);
      }
    },
    async loadSchedule(db, id, isUserSchedule) {
      if (id) {
        await this.loadServerSchedule(db, id, isUserSchedule);
      } else {
        this.resetSchedule(null);
      }
      this.loadHistory(id);
      this.loadLocalSchedule(id, isUserSchedule);
    },
    async createShare(db, schedule, uid) {
      const id = await createShare(db, schedule, uid);
      if (this.user && uid) {
        this.user.shares = id;
      }    
      return id;
    },
    async removeShare(db, scheduleId) {
      await removeShare(db, scheduleId);
    },
    setLastShareTime() {
      this.lastShareTime = Date.now();
    }
  },
});