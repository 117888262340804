import { defineStore } from 'pinia';
import { getDb, initApp, getUser } from '@/helpers/firebase.helper';
import { getSystemModes, getIntroDismissed, setIntroDismissed } from '../helpers/localStorage.helper';
import { getIconName, fetchIcon, fetchAllIcons } from '../helpers/icon.helper';
import { listIcons } from 'oh-vue-icons';
import { setToken } from '../api/Firebase.api';

let onAuthStateChange, signOut, deleteUser;
const loadAuth = async (app) => {
  const { getAuth, onAuthStateChanged, signOut: authSignOut, deleteUser: deluser } = await import("firebase/auth");
  onAuthStateChange = onAuthStateChanged;
  signOut = authSignOut;
  deleteUser = deluser;
  return getAuth(app);
};

export const useSystemStore = defineStore('system', {
  state: () => {
    return {
      modal: [],
      app: null,
      db: null,
      modes: {
        locale: 'en',
        screen: 'minimised',
        display: 'visual',
        scope: 'weekly',
        theme: 'light',
        zoom: 'on'
      },
      window: {
        initialWidth: window.innerWidth,
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight
      },
      printMode: false,
      adsenseReady: false,
      loadedIcons: [],
      allIconsLoaded: false,
      introDismissed: getIntroDismissed(),
      copyMode: false,
      alert: null,
      user: null,
      auth: null
    }
  },
  actions: {
    hideModal() {
      this.modal.pop();
    },
    async initApp(locale) {
      // Do Once
      if (!this.app) {
        this.modes.locale = locale ?? 'en';
        Object.entries(getSystemModes() ?? {}).forEach(([key, value]) => (
          this.modes[key] = value
        ));
        this.app = await initApp();
        this.detectUserLogin();
      }
    },
    async getDb() {
      await this.initApp();
      if (!this.db) {
        this.db = await getDb(this.app);
      }
      return this.db;
    },
    setMode(mode, value) {
      this.modes[mode] = value;
    },
    togglePrintMode() {
      this.printMode = !this.printMode;
    },
    setAdsenseReady() {
      this.adsenseReady = true;
    },
    async loadAllIcons() {
      if (!this.allIconsLoaded) {
        await fetchAllIcons();
        this.loadedIcons.push(
          ...Object.keys(listIcons()).filter((n) => !this.loadedIcons.includes(n))
        );
        this.allIconsLoaded = true;
      }
    },
    async loadIcon(name) {
      if (!this.loadedIcons.length) {
        this.loadedIcons = Object.keys(listIcons());
      }

      if (name && !this.loadedIcons.includes(getIconName(name))) {
        const icon = await fetchIcon(name);
        this.loadedIcons.push(icon);
      }
    },
    setIntroDismissed() {
      this.introDismissed = true;
      setIntroDismissed();
    },
    setAlert(alert) {
      this.alert = alert;
    },
    setCopyMode(active, alert) {
      this.copyMode = active;
      this.setAlert(alert);
    },
    async detectUserLogin(force) {
      const vm = this;
      const db = await window.indexedDB.databases();
      const dbExists = db.some(db => db.name === 'firebaseLocalStorageDb');

      if (!this.auth && (dbExists || force)) {
        this.auth = await loadAuth(this.app);

        onAuthStateChange(this.auth, async (user) => {
          if (user) {
            vm.user = {
              ...user,
              ...await getUser(await vm.getDb(), user.uid),
              uid: user.uid
            };
            vm.auth.currentUser.getIdToken(false).then(setToken);
            vm.modal = [];
          } else {
            vm.user = null;
          }
        });
      }
    },
    async reFetchUser() {
      this.user = {
        ...this.user,
        ...await getUser(await this.getDb(), this.user.uid, false)
      };
    },
    signOut() {
      signOut(this.auth);
    },
    setUserInfo(user) {
      this.user = this.user ?? {};
      Object.entries(user).forEach(([k, v]) => this.user[k] = v);
    },
    deleteUser(i18n) {
      if (prompt(i18n.value.t('system.prompts.deleteAccount')) === 'SBO') {
        const vm = this;
        const user = this.auth.currentUser;

        deleteUser(user).then(() => {
          vm.user = null;
          alert(i18n.value.t('system.alerts.accountDeleted'))
        }).catch((e) => {
          console.log(e)
          alert(i18n.value.t('system.alerts.accountNotDeleted'))
        });
      }
    }
  }
});