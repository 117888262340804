const baseUrl = process.env.NODE_ENV === 'local'
    ? 'http://localhost:5000/schedulebuilder-41fda/us-central1/app/'
    : 'https://us-central1-schedulebuilder-41fda.cloudfunctions.net/app/';

let token;
export const setToken = (t) => token = t;

const getUrl = (endpoint, query = {}) => {
  const url = new URL(endpoint, baseUrl);
  Object.entries(query).forEach(([key, val]) => url.searchParams.append(key, val));

  return url;
};

const get = async (endpoint, query, headers) => {
  const result = await fetch(getUrl(endpoint, query), {
    headers
  });
  const json = await result.json();
  return json;
};

const post = async (endpoint, params, headers) => {
  const result = await fetch(`${baseUrl}${endpoint}`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      ...headers
    },
    body: JSON.stringify(params)
  });
  const json = await result.json();
  return json;
};

export const getBackgroundImages = async(query) => {
  return get('pixabay', { query })
};

export const placeUpvote = async(id) => {
  return post('upvote', { id })
};

export const createSuggestion = async(suggestion) => {
  return post('suggestion', suggestion)
};

export const contact = async(topic, email, message) => {
  return post('contact', { topic, email, message })
};

export const getIp = () => {
  return get('ip');
}

export const getSchedulesByIp = () => {
  return get('schedules/ip');
}

export const getSchedulesByEmail = (email) => {
  return get('schedules/email', { email });
}

export const updateUserInfo = (info) => {
  return post('user/update', info, {
    authorization: token
  });
}
