import { createApp, ref } from 'vue'
import { I18n, dicionaries } from './classes/I18n';
import { OhVueIcon } from "oh-vue-icons";
import { getIconName } from './helpers/icon.helper';
import { createPinia } from 'pinia'
import { useSystemStore } from '@/stores/system.store';
import { useScheduleStore } from '@/stores/schedule.store';
import { router } from '@/router';
import { importIcons } from '@/helpers/icon.helper';
import { getScheduleFromCookies, removeScheduleCookies } from '@/helpers/cookie.helper';
import Main from './Main.vue'

// Parse old local schedules
const oldSchedule = getScheduleFromCookies();
if (oldSchedule) {
  localStorage.setItem('schedule', JSON.stringify(oldSchedule));
  removeScheduleCookies();
}

let locale = 'en';
const urlLocale = location.pathname.replaceAll('/', '');
if (dicionaries[urlLocale]) {
  locale = urlLocale;
}

const app = createApp(Main);
const pinia = createPinia();
const dictionary = await dicionaries[locale]();
const i18n = new I18n(dictionary, locale);

app.use(pinia);
app.use(router);

app.config.globalProperties.locale = locale;
app.config.globalProperties.i18n = ref(i18n);
app.config.globalProperties.getIconName = getIconName;
app.config.globalProperties.system = useSystemStore();
app.config.globalProperties.calendar = useScheduleStore();

importIcons();

app.component("v-icon", OhVueIcon);
app.mount('#app');