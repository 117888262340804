import { diff } from './diff.helper';
import { defaultSettings } from '../stores/schedule.store';

const isLocalStorageAvailable = () => {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

const isCookiesEnabled = () => {
  var cookieEnabled = navigator.cookieEnabled;
  if (!cookieEnabled) {
    document.cookie = "testcookie";
    cookieEnabled = document.cookie.indexOf("testcookie") != -1;
  }
  return cookieEnabled;
};

const readCookie = (name) => (
  JSON.parse(document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))?.[2] ?? '{}')
);

const getItem = (key) => {
  if (isLocalStorageAvailable()) {
    return localStorage.getItem(key);
  } else if (isCookiesEnabled()) {
    return readCookie(key);
  } else {
    return null;
  }
};

const setItem = (key, value) => {
  if (isLocalStorageAvailable()) {
    localStorage.setItem(key, value);
  } else if (isCookiesEnabled()) {
    const timeStamp = parseInt(Date.now().toString().slice(0, -3)) + (34560000);
    document.cookie = `${key}=${value};Max-Age=${timeStamp}`;
  }
};

const removeItem = (key) => {
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(key);
  } else if (isCookiesEnabled()) {
    document.cookie = `${key}=; expires=0;`;
  }
};

const getFullHistory = () => (
  JSON.parse(getItem('history')) || {}
);

export const getHistory = (id) => (
  getFullHistory()[id || 'local'] || {}
);

const setHistory = (changes, id, { index, ready }) => {
  const fullHistory = getFullHistory();
  const currHistory = fullHistory[id || 'local'];
  const currHistoryItems = currHistory?.items || [];
  const newHistoryItems = !ready
    ? [...currHistoryItems.slice(0, index), changes]
    : currHistoryItems;

  const newHistory = JSON.stringify({
    ...fullHistory,
    [id || 'local']: {
      items: newHistoryItems.slice(-100),
      lastChangeTime: !ready ? Date.now() : currHistory?.lastChangeTime,
      index
    }
  });

  setItem('history', newHistory);
  return newHistoryItems;
};


export const removeHistory = (id) => {
  const fullHistory = getFullHistory();
  delete fullHistory[id || 'local'];
  setItem('history', JSON.stringify(fullHistory));
}

export const saveLocalSchedule = (schedule, id, history) => {
  const oldSchedule = JSON.parse(getSchedule(id)) ?? { settings: defaultSettings, events: [] };
  const changes = diff(schedule, oldSchedule);

  setHistory(changes, id, history);
  const newHistory = getHistory(id);

  setItem(`schedule${id || ''}`, JSON.stringify({
    ...schedule,
    lastChangeTime: newHistory.lastChangeTime
  }))

  return newHistory;
};

export const getSchedule = (id) => (
  getItem(`schedule${id || ''}`)
);

export const resetSchedule = (id) => {
  removeItem(`schedule${id || ''}`);
  removeHistory(id);
};

export const getUpvotes = () => (
  getItem('upvotes')
);

export const setUpvoted = (id) => (
  setItem('upvotes', `${localStorage.getItem('upvotes')},${id}`)
);

export const getEmailSchedules = () => {
  const raw = getItem('emailSchedules');
  return raw ? JSON.parse(raw) : [];
};

export const setEmailSchedules = (schedules) => {
  const existing = getEmailSchedules();
  setItem('emailSchedules', JSON.stringify([
    ...existing,
    ...schedules
  ]))
};

export const getHiddenSchedules = () => (
  JSON.parse(getItem('hiddenSchedules') ?? '[]')
);

export const toggleHiddenSchedule = (schedule) => {
  const hidden = getHiddenSchedules();
  const index = hidden.indexOf(schedule);

  if (index >= 0) {
    hidden.splice(index, 1);
  } else {
    hidden.push(schedule);
  }

  setItem('hiddenSchedules', JSON.stringify(hidden));

  return hidden;
};

export const getSystemModes = () => {
  const modes = JSON.parse(getItem('modes') ?? '{}');
  if (modes.display && modes.display === 'graphical') {
    modes.display = 'visual';
  }
  return modes;
};

export const setSystemModes = (modes) => (
  setItem('modes', JSON.stringify(modes))
);

export const getIntroDismissed = () => {
  const schedule = getSchedule();
  const dismissed = (getItem('introDismissed') ?? 'false') === 'true';

  return schedule || dismissed;
};

export const setIntroDismissed = () => (
  setItem('introDismissed', 'true')
);