const readCookie = (name) => (
  JSON.parse(document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))?.[2] ?? '{}')
);

// Parsing of old local schedules
export const getScheduleFromCookies = () => {
  const settings = readCookie('settingsArray');
  const events = readCookie('objectsArray');

  if (!('startDay' in settings) || !(0 in events)) {
    return null;
  }

  let eventId = 1;
  const {
    scheduleTitle, timeFormat, startDay, endDay, startTime, endTime,
    timeMarks, useBackground, backgroundColor, backgroundUrl, autoResize
  } = settings;

  return {
    createdAt: Date.now(),
    settings: {
      title: decodeURIComponent(scheduleTitle ?? ''),
      clockType: timeFormat === 'military' ? '24-hour' : '12-hour',
      weekDays: [...Array((endDay + 1) - startDay).keys()].map((d) => startDay + d),
      lineSpacing: 60,
      timeRange: [
        startTime,
        endTime
      ],
      showDuration: true,
      timeMarksType: timeMarks,
      background: {
        type: useBackground === 'yesColor' ? 1 : (useBackground === 'yes' ? 2 : 0),
        value: useBackground === 'yesColor' ? (backgroundColor?.toLowerCase() === '#d8d8d8' ? '' : backgroundColor) : (useBackground === 'yes' ? backgroundUrl : '')
      },
      autoResize: autoResize === 'yes'
    },
    events: Object.values(events).map((day, index) => (
      Object.values(day).map(({
        objectName, startTime, endTime, objectInfo, backgroundColor
      }) => ({
        id: eventId++,
        title: decodeURIComponent(objectName ?? ''),
        day: index,
        timeRange: [
          `${`0${Math.floor(startTime / 60)}`.slice(-2)}:${`0${startTime % 60}`.slice(-2)}`,
          `${`0${Math.floor(endTime / 60)}`.slice(-2).replace('24', '00')}:${`0${endTime % 60}`.slice(-2)}`
        ],
        description: decodeURIComponent(objectInfo ?? ''),
        colors: {
          color: backgroundColor
        },
      })))).flat(),
    lastSaveId: ''
  };
};

export const removeScheduleCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};